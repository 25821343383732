import { getNumberAsCurrency } from "@helpers/miscelleanous";

export const taxHarvesterRoutes = {
    landingPage: '/taxinsights/harvester',
    dashboard: '/taxinsights/harvester/dashboard',
    offsettingCharges: '/taxinsights/harvester/dashboard/offsetting-charges',
    futureTaxLiability: '/taxinsights/harvester/dashboard/future-tax-liability',
    lossHarvesting: '/taxinsights/harvester/dashboard/loss-harvesting',
    uploadDocuments: '/taxinsights/harvester/upload-documents',
    notEligible: '/taxinsights/harvester/not-eligible',
    completePaymentPage: '/taxinsights/harvester/payment',
    payuPaymentPage: '/taxinsights/harvester/payment/payu',
    paymentStatus: '/taxinsights/harvester/payment/payment-status',
    intro: '/taxinsights/harvester/intro',
};

export const TAX_SAVING_SLUGS = {
    LOSS_HARVESTING: 'loss-harvesting',
    OFFSETTING_CHARGES: 'offsetting-charges',
    FUTURE_TAX_LIABILITY: 'future-tax-liability',
};


/*
console.log(parseRupees("₹16,355"));      // 16355
console.log(parseRupees("- ₹1,25,000"));  // -125000
console.log(parseRupees("₹0"));           // 0
console.log(parseRupees(null));           // 0
console.log(parseRupees(""));             // 0
console.log(parseRupees("y12,222"));      // 0 (Invalid input)
console.log(parseRupees("₹abc123"));      // 0 (Invalid input)
console.log(parseRupees("12.50"));        // 12.5 (Valid number)
*/
function isChrome() {
    return /chrome/i.test(navigator.userAgent) && !/edg|opr|brave/i.test(navigator.userAgent);
}

export const parseRupees = (amount) => {
    if (typeof amount !== 'string' || !amount.trim()) return 0;

    // Validate allowed characters (₹, -, digits, ',', and '.')
    if (isChrome() && !/^[-₹\d,\.]+$/.test(amount)) return 0;

    // Remove non-numeric symbols except the first '.'
    const num = parseFloat(amount.replace(/[^0-9.-]/g, '').replace(/(?!^)-/g, '').replace(/(\..*)\./g, '$1'));
    return isNaN(num) ? 0 : num;
};


export const taxHarvesterDashboardStatus = {
    INITIATED: "Initiated",
    IN_PROGRESS: "InProgress",
    COMPLETED: "Completed",
}

export const taxHarvesterBrandName = 'Tax Harvester';

export const taxHarvesterFeatureId = '064576d2-414b-4653-ac0c-ff1bd94382f7'

export const taxHarvesterStepsDetails = [
    {
        title: 'Advanced Tax Harvesting',
        desc: "Most tools only match realised losses with gains. Our approach helps you book unrealised losses, turning market dips into tax-saving opportunities.",
        image: '/images/tax-insights/landing-img-1.webp',
    },
    {
        title: 'Smart Moves, Lower Future Tax',
        desc: 'Adjust your equity and mutual fund holdings today to reduce your tax burden and enhance future savings.',
        image: '/images/tax-insights/landing-img-2.webp',
    },
    {
        title: 'Seamless & Automatic',
        desc: 'Effortless execution, built-in intelligence, and minimal work—so you save without the stress.',
        image: '/images/tax-insights/landing-img-3.webp',
    },
];

export const formatCurrency = (value) => getNumberAsCurrency(Math.round(value ?? 0));

export const taxHarvesterSupportEmail = 'taxinsights@altgraaf.com';

export const paidPlanBenefits = [
    'Analysis of your current investments',
    'Summary of your current tax liability',
    'Deep-dive into each component of your short-term and long-term gains',
    'Tailored Tax Strategy for each component',
    'Actionable blueprint',
]

export const onlyPaidPlanBenefits = [
    'Analysis of your current investments',
    'Deep-dive into each component of your short-term and long-term gains',
    'Tailored Tax Strategy for each component',
    'Actionable blueprint',
]

export const PAYMENT_STATUS = {
    CREATED: "Created",
    COMPLETED: "Success",
    PENDING: "Pending",
    FAILED: "Failed",
    AMOUNT_MISMATCH: "AmountMismatch",
}